
export const tableOption = {
  search: true,
  height: '100%',
  menuWidth: 150,
  menuAlign: 'center',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSpan: 6,
  selection: false,
  searchLabelWidth: 90,
  column: []
}

